html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

div#root {
  height: 100%;
}
